<template>
  <v-dialog
    persistent
    v-model="changeStatus.dialog"
    max-width="400px"
    @click:outside="resetChangeStatus()"
  >
    <v-card>
      <v-card-title class="headline">Change Status</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <div class="mb-5">
          Are you Sure you want to change status to
          {{ changeStatus.form_status }} for all applications?
        </div>
        <v-text-field
          v-if="changeStatus.form_status == 'Returned'"
          label="Comment Text"
          type="textarea"
          v-model="changeStatus.comment_text"
          outlined
          background-color="white"
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetChangeStatus()"
          >No, Cancel</v-btn
        >
        <v-btn
          color="accent"
          @click="changeStatusConfirmed()"
          :loading="changeStatus.loading"
          >Yes</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      form_status: null,
      changeStatus: {
        form_status: null,
        errors: {},
        dialog: false,
        loading: false,
        comment_text: null,
        group_id: null,
      },
    };
  },

  methods: {
    changeStatusPrompt(form_status, group_id = null) {
      this.changeStatus.form_status = form_status;
      this.changeStatus.group_id = group_id;
      this.changeStatus.dialog = true;
    },
    resetChangeStatus() {
      this.changeStatus.form_status = null;
      this.changeStatus.comment_text = null;
      this.changeStatus.errors = null;
      this.changeStatus.group_id = null;
      this.changeStatus.dialog = false;
      this.changeStatus.loading = false;
    },
    changeStatusConfirmed() {
      this.changeStatus.loading = true;

      let payload = {
        appId: this.$route.params.id,
        groupId: this.changeStatus.group_id,
        form_status: this.changeStatus.form_status,
      };

      this.$store
        .dispatch("easylets/propertiesStore/changeGroupStatus", payload)
        .then(() => {
          this.resetChangeStatus();
        })
        .catch((err) => {
          this.changeStatus.errors = err.response.data.errors;
          this.changeStatus.loading = false;
        });
    },
  },
};
</script>
