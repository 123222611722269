<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-easylets-properties' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Properties</v-btn
        >
      </v-row>
      <v-row align="center" no-gutters>
        <v-col cols="auto" class="pr-12">
          <h1>Property Applications</h1>
        </v-col>
      </v-row>

      <v-icon left>mdi-map-marker</v-icon>
      {{ property.full_address }}
      <v-icon left class="ml-2">mdi-cash</v-icon>£{{
        formatPrice(property.monthly_rent)
      }}p/m

      <v-icon left class="ml-2">mdi-home-clock</v-icon>

      Available -
      {{ property.available_date ? formatDate(property.available_date) : "" }}

      <v-row>
        <v-col>
          <v-simple-table
            class="mb-5 table table-bordered"
            divider="true"
            v-for="group in applications_group"
            v-bind:key="group.application_group_id"
          >
            <tbody>
              <tr>
                <td>
                  <strong>Group - {{ group.lead.full_name }} </strong>
                  <v-tooltip bottom v-if="group.number_of_people_sent">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        ({{ group.number_of_people_sent }})
                      </div>
                    </template>

                    <span
                      v-bind:key="a.email"
                      v-for="(a, i) in JSON.parse(group.list_of_people)"
                    >
                      <div>{{ i }}{{ a.name }} - {{ a.email }}</div>
                    </span> </v-tooltip
                  ><br />
                  Combined annual income: £{{ formatPrice(group.annual_income)
                  }}<br />

                  Preferred move in date:
                  {{
                    group.applications[0].preferred_move_in_date
                      ? formatDate(group.applications[0].preferred_move_in_date)
                      : ""
                  }}<br />
                  Viewing date:
                  {{
                    group.applications[0].viewed_property_date
                      ? formatDate(group.applications[0].viewed_property_date)
                      : ""
                  }}<br />
                </td>
                <th>Status</th>
                <th>Bank Statement</th>
                <th>Photo ID</th>
                <th>Employer</th>
                <th>Payslips</th>
                <th>Student ID</th>
                <th>Acceptance Letter</th>
                <th>Reference</th>
                <th>Guarantor</th>
                <th>
                  <v-btn
                    v-if="group.application_group_id"
                    x-small
                    depressed
                    color="yellow lighten-4 black--text"
                    class="mr-2"
                    @click="
                      $refs.statusGroupDialog.changeStatusPrompt(
                        'Accepted',
                        group.application_group_id
                      )
                    "
                  >
                    Accept
                  </v-btn>

                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    class="mr-2"
                    @click="
                      $refs.statusGroupDialog.changeStatusPrompt(
                        'Rejected',
                        group.application_group_id
                      )
                    "
                  >
                    Reject
                  </v-btn>

                  <v-btn
                    x-small
                    class="mr-2"
                    depressed
                    color="green lighten-4 green--text"
                    @click="$refs.inviteDialog.open(group)"
                  >
                    New Invite
                  </v-btn>

                  <v-btn
                    v-if="groupVerified(group.application_group_id)"
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    @click="
                      $refs.exportGroupToAgentos.sendGroupAgentosPrompt(
                        group.application_group_id
                      )
                    "
                  >
                    Export to AgentOS
                  </v-btn>
                </th>
              </tr>

              <tr
                @click.self="rowClick(application)"
                class="text-center"
                v-for="application in group.applications"
                v-bind:key="application.application_id"
              >
                <td class="text-left">
                  <v-icon v-if="application.tenant_type == 'student'" left
                    >mdi-account</v-icon
                  >
                  <v-icon v-else left>mdi-file-document-edit</v-icon>
                  <div v-if="application.customer_id">
                    {{ application.customer.full_name }}
                  </div>
                </td>
                <td>{{ application.form_status }}</td>
                <td>
                  <div
                    v-if="application.tenant_type == 'student'"
                    class="empty_slot"
                  ></div>
                  <div v-else>
                    <v-icon
                      :color="iconColor(application.bank_statements_status)"
                    >
                      {{ imageStatus(application.bank_statements_status) }}
                    </v-icon>
                  </div>
                </td>
                <td>
                  <div
                    v-if="application.tenant_type == 'student'"
                    class="empty_slot"
                  ></div>
                  <div v-else>
                    <v-icon :color="iconColor(application.photo_id_status)">
                      {{ imageStatus(application.photo_id_status) }}
                    </v-icon>
                  </div>
                </td>
                <td>
                  <div
                    v-if="application.tenant_type == 'student'"
                    class="empty_slot"
                  ></div>
                  <div v-else></div>
                </td>
                <td>
                  <div
                    v-if="application.tenant_type == 'student'"
                    class="empty_slot"
                  ></div>
                  <div v-else>
                    <v-icon :color="iconColor(application.payslips_status)">
                      {{ imageStatus(application.payslips_status) }}
                    </v-icon>
                  </div>
                </td>
                <td>
                  <div v-if="application.tenant_type == 'student'">
                    <v-icon :color="iconColor(application.student_id_status)">
                      {{ imageStatus(application.student_id_status) }}
                    </v-icon>
                  </div>

                  <div v-else class="empty_slot"></div>
                </td>
                <td>
                  <div v-if="application.tenant_type == 'student'">
                    <v-icon
                      :color="iconColor(application.uni_acceptance_status)"
                    >
                      {{ imageStatus(application.uni_acceptance_status) }}
                    </v-icon>
                  </div>
                  <div v-else class="empty_slot"></div>
                </td>
                <td>
                  <div v-if="application.tenant_type == 'student'">
                    <v-icon :color="iconColor(application.landlord_ref_status)">
                      {{ imageStatus(application.landlord_ref_status) }}
                    </v-icon>
                  </div>
                  <div v-else class="empty_slot"></div>
                </td>
                <td>
                  <div>
                    <v-icon :color="iconColor(application.guarantor_status)">
                      {{ imageStatus(application.guarantor_status) }}
                    </v-icon>
                  </div>
                </td>
                <td>
                  <v-btn
                    x-small
                    depressed
                    color="green lighten-4 green--text"
                    class="mr-2"
                    @click="
                      $refs.statusDialog.changeStatusPrompt(
                        'Verified',
                        application.application_id
                      )
                    "
                  >
                    Verify
                  </v-btn>

                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    class="mr-2"
                    :to="{
                      name: 'module-easylets-applicationforms-individual',
                      params: { formId: application.application_id },
                    }"
                  >
                    View
                  </v-btn>

                  <v-btn
                    bottom
                    x-small
                    depressed
                    class="mr-2"
                    color="red lighten-4 red--text"
                    @click="openDelete(application, true)"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <br /><br />

          <v-simple-table
            class="mb-5 table-bordered"
            v-for="application in applications"
            v-bind:key="application.application_id"
          >
            <tbody>
              <tr>
                <th>
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    class="mr-2"
                    @click="
                      $refs.mergeDialog.mergeForm(application.application_id)
                    "
                  >
                    Merge
                  </v-btn>
                </th>
                <th>Status</th>
                <th>Bank Statement</th>
                <th>Photo ID</th>
                <th>Employer</th>
                <th>Payslips</th>
                <th>Student ID</th>
                <th>Acceptance Letter</th>
                <th>Reference</th>
                <th>Guarantor</th>
                <th>
                  <v-btn
                    x-small
                    depressed
                    color="yellow lighten-4 black--text"
                    class="mr-2"
                    @click="
                      $refs.statusDialog.changeStatusPrompt(
                        'Accepted',
                        application.application_id
                      )
                    "
                  >
                    Accept
                  </v-btn>

                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    class="mr-2"
                    @click="
                      $refs.statusDialog.changeStatusPrompt(
                        'Rejected',
                        application.application_id
                      )
                    "
                  >
                    Reject
                  </v-btn>
                </th>
              </tr>

              <tr>
                <td>
                  Preferred move in date:
                  {{
                    application.preferred_move_in_date
                      ? formatDate(application.preferred_move_in_date)
                      : ""
                  }}<br />
                  Viewing date:
                  {{
                    application.viewed_property_date
                      ? formatDate(application.viewed_property_date)
                      : ""
                  }}<br />

                  <v-icon v-if="application.tenant_type == 'student'" left
                    >mdi-account</v-icon
                  >
                  <v-icon v-else left>mdi-file-document-edit</v-icon>
                  <div v-if="application.customer_id">
                    {{ application.customer.full_name }}
                  </div>
                </td>
                <td>{{ application.form_status }}</td>
                <td>
                  <div
                    v-if="application.tenant_type == 'student'"
                    class="empty_slot"
                  ></div>
                  <div v-else>
                    <v-icon
                      :color="iconColor(application.bank_statements_status)"
                    >
                      {{ imageStatus(application.bank_statements_status) }}
                    </v-icon>
                  </div>
                </td>
                <td>
                  <div
                    v-if="application.tenant_type == 'student'"
                    class="empty_slot"
                  ></div>
                  <div v-else>
                    <v-icon :color="iconColor(application.photo_id_status)">
                      {{ imageStatus(application.photo_id_status) }}
                    </v-icon>
                  </div>
                </td>
                <td>
                  <div
                    v-if="application.tenant_type == 'student'"
                    class="empty_slot"
                  ></div>
                  <div v-else></div>
                </td>
                <td>
                  <div
                    v-if="application.tenant_type == 'student'"
                    class="empty_slot"
                  ></div>
                  <div v-else>
                    <v-icon :color="iconColor(application.payslips_status)">
                      {{ imageStatus(application.payslips_status) }}
                    </v-icon>
                  </div>
                </td>
                <td>
                  <div v-if="application.tenant_type == 'student'">
                    <v-icon :color="iconColor(application.student_id_status)">
                      {{ imageStatus(application.student_id_status) }}
                    </v-icon>
                  </div>

                  <div v-else class="empty_slot"></div>
                </td>
                <td>
                  <div v-if="application.tenant_type == 'student'">
                    <v-icon
                      :color="iconColor(application.uni_acceptance_status)"
                    >
                      {{ imageStatus(application.uni_acceptance_status) }}
                    </v-icon>
                  </div>
                  <div v-else class="empty_slot"></div>
                </td>
                <td>
                  <div v-if="application.tenant_type == 'student'">
                    <v-icon :color="iconColor(application.landlord_ref_status)">
                      {{ imageStatus(application.landlord_ref_status) }}
                    </v-icon>
                  </div>
                  <div v-else class="empty_slot"></div>
                </td>
                <td>
                  <div>
                    <v-icon :color="iconColor(application.guarantor_status)">
                      {{ imageStatus(application.guarantor_status) }}
                    </v-icon>
                  </div>
                </td>
                <td>
                  <v-btn
                    x-small
                    depressed
                    color="green lighten-4 green--text"
                    class="mr-2"
                    @click.stop="
                      $refs.statusDialog.changeStatusPrompt(
                        'Verified',
                        application.application_id
                      )
                    "
                  >
                    Verify
                  </v-btn>

                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    class="mr-2"
                    :to="{
                      name: 'module-easylets-applicationforms-individual',
                      params: { formId: application.application_id },
                    }"
                  >
                    View
                  </v-btn>

                  <v-btn
                    bottom
                    x-small
                    depressed
                    class="mr-2"
                    color="red lighten-4 red--text"
                    @click="openDelete(application, true)"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
    <status-dialog ref="statusDialog" />
    <status-group-dialog ref="statusGroupDialog" />
    <merge-dialog ref="mergeDialog" />
    <invite-dialog ref="inviteDialog" />
    <export-group-agentos-dialog ref="exportGroupToAgentos" />

    <v-dialog v-model="deleteForm.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Form</v-card-title>
        <v-card-text>Are you sure you wish to archive form?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteForm.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import StatusDialog from "../applicationforms/components/StatusDialog.vue";
import StatusGroupDialog from "../applicationforms/components/StatusGroupDialog";
import ExportGroupAgentosDialog from "../applicationforms/components/ExportGroupToAgentos";
import MergeDialog from "./components/MergeDialog.vue";
import InviteDialog from "./components/InviteDialog.vue";

export default {
  components: {
    StatusDialog,
    StatusGroupDialog,
    ExportGroupAgentosDialog,
    MergeDialog,
    InviteDialog,
  },

  data() {
    return {
      deleteForm: {
        dialog: false,
        form: {},
        loading: false,
      },
      activeHover: false,
      myIcon: {
        name: "mdi-unfold-less-vertical",
        color: "default",
      },
      breadcrumbs: [
        {
          text: "Properties",
          disabled: false,
          to: { name: "module-easylets-properties" },
          exact: true,
        },
      ],
      proffesionalTableHeaders: [
        { text: "Group", value: "group_title" },
        { text: "Status", value: "status" },
        { text: "Photo ID", value: "photo_id_status" },
        { text: "Payslips", value: "payslips_status" },
        { text: "Bank Statement", value: "bank_statements_status" },
        { text: "Reference", value: "landlord_ref_status" },
        { text: "Guarantor", value: "guarantor_status" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],

      studentTableHeaders: [
        { text: "Group", value: "group_title" },
        { text: "Status", value: "status" },
        { text: "Photo ID", value: "photo_id_status" },
        { text: "Student ID", value: "student_id_status" },
        { text: "Acceptance Letter", value: "uni_acceptance_status" },
        { text: "Reference", value: "landlord_ref_status" },
        { text: "Guarantor", value: "guarantor_status" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    property() {
      return this.$store.getters["easylets/propertiesStore/get"];
    },

    applications() {
      return this.$store.getters["easylets/propertiesStore/applications"];
    },

    applications_group() {
      return this.$store.getters["easylets/propertiesStore/applications_group"];
    },
  },
  methods: {
    groupVerified(groupId) {
      let group = this.applications_group.filter(
        (b) => b.application_group_id === groupId
      );

      let verifiedApplication = [];

      if (group[0].applications.length > 0) {
        group[0].applications.forEach((application) => {
          if (
            application.form_status == "Exported to Agent OS" ||
            application.form_status == "Verified" ||
            application.form_status == "Accepted"
          )
            verifiedApplication.push({
              application,
            });
        });

        if (group[0].applications.length == verifiedApplication.length) {
          return true;
        }
      }
    },

    openDelete(form) {
      this.deleteForm.form = form;
      this.deleteForm.dialog = true;
    },

    resetDelete() {
      this.deleteForm.dialog = false;
      this.deleteForm.form = {};
      this.deleteForm.loading = false;
    },

    saveDelete() {
      this.deleteForm.loading = true;

      this.$store
        .dispatch("easylets/propertiesStore/deleteForm", {
          appId: this.$route.params.id,
          formId: this.deleteForm.form.application_id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteForm.loading = false;
        });
    },

    rowClick: function (item) {
      this.$router.push({
        name: "module-easylets-applicationforms-individual",
        params: { formId: item.application_id },
      });
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    iconColor(status) {
      if (status == null) {
        return "red";
      } else if (status == "Verified") {
        return "blue";
      } else if (status == "Sent Back" || status == "Returned") {
        return "orange";
      } else {
        return "green";
      }
    },

    imageStatus(status) {
      if (status == null || status == "Returned") {
        return "mdi-close-circle";
      } else if (status == "Verified") {
        return "mdi-check-circle";
      } else if (status == "Sent Back") {
        return "mdi-alert-circle";
      } else {
        return "mdi-check-circle";
      }
    },
  },
};
</script>
<style>
.table-bordered {
  border: thin solid rgba(0, 0, 0, 0.12);
}
.table-bordered tr td,
.table-bordered tr th {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}

.empty_slot {
  background: #ccc;
  height: 100px;
  padding: 0px;
}

.table-bordered td:has(.empty_slot) {
  padding: 0px !important;
}
</style>
