<template>
  <v-dialog v-model="dialog" max-width="400px" @click:outside="reset()">
    <v-card>
      <v-card-title class="headline">Invite to group</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-text-field
          label="Name"
          type="text"
          v-model="fields.name"
          outlined
          background-color="white"
        ></v-text-field>
        <v-text-field
          label="Email"
          type="text"
          v-model="fields.email"
          outlined
          background-color="white"
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">No, Cancel</v-btn>
        <v-btn color="accent" @click="confirm()" :loading="loading">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      group: {},
      fields: {
        name: null,
        email: null,
      },
    };
  },

  computed: {},

  methods: {
    open(group) {
      this.dialog = true;
      this.group = group;
    },
    reset: function () {
      this.fields.email = null;
      this.fields.name = null;
      this.dialog = false;
      this.loading = false;
      this.group = {};
    },
    confirm: function () {
      this.loading = true;

      let payload = {
        appId: this.$route.params.id,
        propertyId: this.$route.params.propertyId,
        groupId: this.group.application_group_id,
        fields: this.fields,
      };

      this.$store
        .dispatch("easylets/propertiesStore/inviteToGroup", payload)
        .then(() => {
          this.reset();
        })
        .catch(() => {
          this.reset();
        });
    },
  },
};
</script>
