<template>
  <v-dialog v-model="dialog" max-width="400px" @click:outside="reset()">
    <v-card>
      <v-card-title class="headline">Change Status</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <div class="mb-5">Are you sure you want to merge to group?</div>
        <v-select
          label="Group"
          v-model="fields.application_group_id"
          :items="groups"
          item-value="application_group_id"
          :item-text="
            (item) => item.lead.full_name + ' - ' + item.application_group_id
          "
          outlined
          background-color="white"
        >
        </v-select>

        <v-select
          label="Individual"
          v-model="fields.application_id"
          :items="applications"
          item-value="application_id"
          :item-text="
            (item) => item.customer.full_name + ' - ' + item.application_id
          "
          outlined
          background-color="white"
        >
        </v-select>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">No, Cancel</v-btn>
        <v-btn color="accent" @click="confirm()" :loading="loading">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      fields: {
        application_group_id: null,
        form_id: null,
      },
    };
  },

  computed: {
    groups() {
      return this.$store.getters["easylets/propertiesStore/applications_group"];
    },

    applications() {
      return this.$store.getters[
        "easylets/propertiesStore/applications"
      ].filter((s) => s.application_id != this.fields.form_id);
    },
  },

  methods: {
    mergeForm: function (form_id) {
      this.fields.form_id = form_id;
      this.dialog = true;
    },
    reset: function () {
      this.fields.application_group_id = null;
      this.fields.application_id = null;
      this.fields.form_id = null;
      this.dialog = false;
      this.loading = false;
    },
    confirm: function () {
      const appId = this.$route.params.id;
      const propertyId = this.$route.params.propertyId;

      this.loading = true;

      this.$store
        .dispatch("easylets/propertiesStore/assignFormToGroup", {
          appId,
          propertyId,
          form_id: this.fields.form_id,
          application_group_id: this.fields.application_group_id,
          application_id: this.fields.application_id,
        })
        .then(() => {
          this.reset();
        })
        .catch(() => {
          this.reset();
        });
    },
  },
};
</script>
