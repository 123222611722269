<template>
  <v-dialog
    persistent
    v-model="sendGroupAgentos.dialog"
    max-width="400px"
    @click:outside="resetSendGroupAgentos()"
  >
    <v-card>
      <v-card-title class="headline"
        >Export all applications to AgentOs</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <div class="mb-5">
          Are you Sure you want to export all applications?
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetSendGroupAgentos()"
          >No, Cancel</v-btn
        >
        <v-btn
          color="accent"
          @click="sendAgentosGroupConfirm()"
          :loading="sendGroupAgentos.loading"
          >Yes</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      form_status: null,
      sendGroupAgentos: {
        errors: {},
        dialog: false,
        loading: false,
        comment_text: null,
        group_id: null,
      },
    };
  },

  methods: {
    sendGroupAgentosPrompt(group_id = null) {
      this.sendGroupAgentos.group_id = group_id;
      this.sendGroupAgentos.dialog = true;
    },
    resetSendGroupAgentos() {
      this.sendGroupAgentos.comment_text = null;
      this.sendGroupAgentos.errors = null;
      this.sendGroupAgentos.group_id = null;
      this.sendGroupAgentos.dialog = false;
      this.sendGroupAgentos.loading = false;
    },

    sendAgentosGroupConfirm() {
      this.sendGroupAgentos.loading = true;

      this.$store
        .dispatch("easylets/propertiesStore/sendGroupAgentos", {
          appId: this.$route.params.id,
          groupId: this.sendGroupAgentos.group_id,
        })
        .then(() => {
          this.resetSendGroupAgentos();
        })
        .catch(() => {
          this.sendGroupAgentos.loading = false;
        });
    },
  },
};
</script>
